
import { defineComponent } from 'vue';

import FormInputText from '@/components/FormInputText.vue';
import Button from '@/components/Button.vue';
import Title from '@/components/Title.vue';
import Callout from '@/components/Callout.vue';
import api from '@/modules/api';

import Validation from '@/modules/validation';
import {
  HighlightedTitle, CalloutContent, FieldsObject,
} from '@/types/data-types';

export default defineComponent({
  name: 'NewProject',
  components: {
    FormInputText,
    Button,
    Title,
    Callout,
  },
  props: {
  },
  data() {
    return {
      fields: {
        title: {
          value: '',
          error: '',
        },
        category: {
          value: '',
          error: '',
        },
        year: {
          value: '',
          error: '',
        },
        private: {
          value: '',
          error: '',
        },
        respositoryPlatform: {
          value: '',
          error: '',
        },
        respositoryName: {
          value: '',
          error: '',
        },
        respositoryUrl: {
          value: '',
          error: '',
        },
        publicLinkUrl: {
          value: '',
          error: '',
        },
        publicLinkTags: {
          value: '',
          error: '',
        },
        devLinkUrl: {
          value: '',
          error: '',
        },
        devLinkTags: {
          value: '',
          error: '',
        },
        databaseTitle: {
          value: '',
          error: '',
        },
        databaseUrl: {
          value: '',
          error: '',
        },
        databaseTags: {
          value: '',
          error: '',
        },
      } as FieldsObject,
      title: {
        prepend: 'New ',
        highlighted: 'Project',
      } as HighlightedTitle,
      error: {},
      fullError: {},
      success: {
        state: false,
        content: {},
      },
    };
  },
  computed: {
    token(): string {
      return this.$store.state.token;
    },
  },
  methods: {
    async createHandler() {
      // clean up errors
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const field in this.fields) {
        this.fields[field].error = '';
      }

      const data = {
        title: this.fields.title.value,
        category: this.fields.category.value,
        year: this.fields.year.value,
        private: true,
        publicLink: {
          url: this.fields.publicLinkUrl.value,
          tags: this.fields.publicLinkTags.value.split(', '),
        },
        devLink: {
          url: this.fields.devLinkUrl.value,
          tags: this.fields.devLinkTags.value.split(', '),
        },
        repository: {
          platform: this.fields.respositoryPlatform.value,
          name: this.fields.respositoryName.value,
          url: this.fields.respositoryUrl.value,
        },
        database: {
          title: this.fields.databaseTitle.value,
          url: this.fields.databaseUrl.value,
          tags: this.fields.databaseTags.value.split(', '),
        },
      };

      // api call
      try {
        const response = await api.post('/projects', data, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        console.log(`Project ${response.data.projectId} successfully created.`);

        this.success.content = {
          title: 'Request successful!',
          message: `The project ${response.data.project.title} was successfully created.`,
        } as CalloutContent;
        this.success.state = true;

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const field in this.fields) {
          this.fields[field].value = '';
        }
      } catch (error) {
        this.fullError = error;
        this.error = {
          title: (error.response) ? error.response?.data.message : error.message,
          message: Validation.generateErrorMessage(error.response?.data.fieldErrors),
        } as CalloutContent;

        Validation.matchInputErros(this.fields, error.response?.data.fieldErrors);
      }
    },
  },
});
