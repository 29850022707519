
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Button',
  props: {
    to: {
      required: false,
      defualt: '',
      type: String,
    },
    submit: {
      required: false,
      default: false,
      type: Boolean,
    },
    large: {
      required: false,
      default: false,
      type: Boolean,
    },
    type: {
      required: false,
      default: 'default',
      type: String,
    },
  },
  computed: {
    classList(): any[] {
      return [
        { 'button-large': this.large },
        'button',
        `button-${this.type}`,
      ];
    },
  },
});
