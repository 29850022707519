
import { defineComponent, PropType } from 'vue';
import CustomLink from '@/components/CustomLink.vue';

import { Link } from '../types/data-types';

export default defineComponent({
  name: 'TagLink',
  components: {
    CustomLink,
  },
  props: {
    callback: {
      type: Function as PropType<() => void>,
    },
    label: {
      type: String,
      required: true,
    },
    linkData: {
      type: Object as PropType<Link>,
      required: true,
      validator(projectData: Link) {
        return !!projectData.url;
      },
    },
  },
  computed: {
    url(): string {
      if (!this.linkData.title) {
        return `https://${this.linkData.url}`;
      }

      return this.linkData.url;
    },
  },
});
