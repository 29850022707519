
import { defineComponent, PropType } from 'vue';
import { HighlightedTitle } from '../types/data-types';

export default defineComponent({
  name: 'Title',
  props: {
    callback: {
      type: Function as PropType<() => void>,
    },
    title: {
      type: Object as PropType<HighlightedTitle>,
      required: true,
    },
    subtitle: String,
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
