
import { defineComponent } from 'vue';
import Title from '@/components/Title.vue';
import ProjectCard from '@/components/ProjectCard.vue';
import CustomLink from '@/components/CustomLink.vue';
import ErrorBanner from '@/components/ErrorBanner.vue';
import api from '@/modules/api';

import { HighlightedTitle, ProjectCategory, ProjectData } from '../types/data-types';

export default defineComponent({
  name: 'Home',
  components: {
    Title,
    ProjectCard,
    CustomLink,
    ErrorBanner,
  },
  data() {
    return {
      title: {
        highlighted: 'Code',
        append: ' Projects',
      } as HighlightedTitle,
      subtitle: 'by André Lergier | lergier.ch',
      introduction: 'This page is a personal collection of my different coding projects. It helps me to keep an overview of the git repositories, dev-, stage- and production-deployments and their particular hosting. You need to be logged in to see all project details.',
      projects: [],
      loading: true,
      errored: false,
      error: {},
      tempCategory: '',
    };
  },
  computed: {
    token(): string {
      let token = '';
      token = (this.$store.state.token) ? this.$store.state.token : localStorage.getItem('token') as string;
      // token = this.$store.state.token;
      return token;
    },
    restructuredProjects(): ProjectCategory[] {
      let tempCategory = '';
      let tempProjects: ProjectData[] = [];
      const projectCategories: ProjectCategory[] = []; // array to return

      // eslint-disable-next-line no-restricted-syntax
      for (const [index, projectParam] of this.projects.entries()) {
        const project: ProjectData = projectParam as ProjectData;

        if (tempCategory !== project.category) {
          // new category -> push previous
          if (index !== 0) {
            const projectCategory: ProjectCategory = {
              category: tempCategory,
              projects: tempProjects,
            };

            projectCategories.push(projectCategory);
            tempProjects = [];
          }

          tempCategory = project.category;
        }

        tempProjects.push(project);
      }
      // add last category
      projectCategories.push({
        category: tempCategory,
        projects: tempProjects,
      });

      tempProjects = [];
      tempCategory = '';

      return projectCategories;
    },
  },
  mounted() {
    api.get('/projects', {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }) // same as axios.get('http://localhost:4433/projects');
      .then((response) => {
        this.projects = response.data.projects;
      })
      .catch((error) => {
        console.log('catch view get');
        this.errored = true;
        this.error = error;
      }).finally(() => {
        this.loading = false;
      });
  },
});
