
import { defineComponent } from 'vue';

import Button from '@/components/Button.vue';
import Auth from '@/modules/auth';
import { User } from '@/types/data-types';

export default defineComponent({
  name: 'TheNavigation',
  components: {
    Button,
  },
  computed: {
    token(): string {
      return this.$store.state.token;
    },
    isAuth(): boolean {
      return Auth.isAuthenticated();
    },
    user(): User {
      return this.$store.state.user as User;
    },
    username(): string {
      let username = '';
      if (this.user.name?.firstname) {
        username = `${this.user.name.firstname}`;
      } else {
        username = this.user.email;
      }
      return username;
    },
  },
  methods: {
    logout() {
      this.$store.commit('logout');
      this.$router.go(0); // this.$router.currentRoute
    },
  },
});
