
import { defineComponent } from 'vue';

import FormInputError from '@/components/FormInputError.vue';

export default defineComponent({
  name: 'FormInputText',
  components: {
    FormInputError,
  },
  props: {
    label: String,
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    autocomplete: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: undefined,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      id: '',
      showPassword: false,
    };
  },
  computed: {
    inputType(): string {
      if (this.type === 'password') {
        if (this.showPassword) {
          return 'text';
        }
        return 'password';
      }
      return this.type;
    },
  },
  mounted() {
    this.id = `inputID-${this.uuidv4()}`;
  },
  methods: {
    // eslint-disable-next-line no-bitwise, no-mixed-operators
    uuidv4: (): string => (1e7 + -1e3 + -4e3 + -8e3 + -1e11).toString().replace(/[018]/g, (c: string): string => (parseInt(c, 2) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> parseInt(c, 2) / 4).toString(16)),
  },
});
