
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CustomLink',
  props: {
    link: {
      required: true,
      type: String,
    },
    external: {
      required: false,
      type: Boolean,
      default: false,
    },
    title: {
      required: false,
      type: String,
      default: '',
    },
  },
});
