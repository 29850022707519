
import { defineComponent, PropType } from 'vue';

import { CalloutContent } from '@/types/data-types';

export default defineComponent({
  name: 'Callout',
  props: {
    callback: {
      type: Function as PropType<() => void>,
    },
    content: {
      required: true,
      type: Object as PropType<CalloutContent>,
    },
    type: {
      type: String,
      default: 'error',
      required: false,
    },
  },
  computed: {
    details(): string[] {
      const details = [];
      if (this.content.fieldErrors?.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const validationError of this.content.fieldErrors) {
          details.push(validationError.msg);
        }
      }
      return details;
    },
  },
});
