
import { defineComponent, PropType } from 'vue';
import CustomLink from '@/components/CustomLink.vue';
import TagLink from '@/components/TagLink.vue';
import Auth from '@/modules/auth';

import { ProjectData } from '../types/data-types';

export default defineComponent({
  name: 'ProjectCard',
  components: {
    CustomLink,
    TagLink,
  },
  props: {
    callback: {
      type: Function as PropType<() => void>,
    },
    projectData: {
      type: Object as PropType<ProjectData>,
      required: true,
      validator(projectData: ProjectData) {
        return !!projectData.title;
      },
    },
  },
  computed: {
    isAuth(): boolean {
      return Auth.isAuthenticated();
    },
    platformIcon(): string {
      return this.projectData?.repository.platform.toLowerCase();
    },
  },
});
