
import { defineComponent } from 'vue';

import FormInputText from '@/components/FormInputText.vue';
import Button from '@/components/Button.vue';
import Title from '@/components/Title.vue';
import Callout from '@/components/Callout.vue';
import api from '@/modules/api';

import Validation from '@/modules/validation';
import {
  HighlightedTitle, CalloutContent, FieldsObject,
} from '@/types/data-types';

export default defineComponent({
  name: 'Signup',
  components: {
    FormInputText,
    Button,
    Title,
    Callout,
  },
  props: {
  },
  data() {
    return {
      fields: {
        firstname: {
          value: '',
          error: '',
        },
        familyname: {
          value: '',
          error: '',
        },
        email: {
          value: '',
          error: '',
        },
        password: {
          value: '',
          error: '',
        },
        passwordConfirmation: {
          value: '',
          error: '',
        },
      } as FieldsObject,
      title: {
        prepend: 'Request ',
        highlighted: 'Account',
      } as HighlightedTitle,
      error: {},
      fullError: {},
      success: {
        state: false,
        content: {},
      },
    };
  },
  computed: {
    token(): string {
      return this.$store.state.token;
    },
  },
  methods: {
    async signupHandler() {
      // clean up errors
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const field in this.fields) {
        this.fields[field].error = '';
      }

      // api call
      try {
        const response = await api.post('/user', {
          firstname: this.fields.firstname.value,
          familyname: this.fields.familyname.value,
          email: this.fields.email.value,
          password: this.fields.password.value,
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        console.log(`User ${response.data.userId} successfully created.`);

        this.success.content = {
          title: 'Request successful!',
          message: `Thank you, ${this.fields.firstname.value}! Your Account was successfully requested.`,
        } as CalloutContent;
        this.success.state = true;

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const field in this.fields) {
          this.fields[field].value = '';
        }
      } catch (error) {
        this.fullError = error;
        this.error = {
          title: (error.response) ? error.response?.data.message : error.message,
          message: Validation.generateErrorMessage(error.response?.data.fieldErrors),
        } as CalloutContent;

        Validation.matchInputErros(this.fields, error.response?.data.fieldErrors);
      }
    },
  },
});
