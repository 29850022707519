
import { defineComponent } from 'vue';

// import { Error } from '../types/data-types';

export default defineComponent({
  name: 'ErrorBanner',
  props: {
    /* callback: {
      type: Function as PropType<() => void>,
    }, */
    error: {
      required: true,
      type: Object,
      // as PropType<Error> -> fix Property 'response'
      // does not exist on type 'object'.Vetur(2339)
    },
  },
  computed: {
    details(): string {
      /* let details: object = {};

      if (this.error?.response.data) {
        console.log('1');
        details = this.error?.response?.data;
      } else if (this.error?.config) {
        console.log('2');
        details = this.error?.config;
      } else {
        console.log('3');
        details = this.error;
      }
      console.log('4');
      // return this.error; */

      return JSON.stringify(this.error);
    },
  },
});
